import {
  Box,
  Button,
  Container,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { dehydrate } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';
import qs from 'query-string';

import Layout from '@orgnc/core/components/common/Layout';
import DocumentHeadOrganicTags from '@orgnc/core/components/common/OrganicSDK/DocumentHeadOrganicTags';
import { ContentType } from '@orgnc/core/components/organisms/Templates/helpers/dataOgAttributes';
import {
  CenterLayout,
  GridLayout,
  StackLayout,
} from '@orgnc/core/components/primitives';
import useAds from '@orgnc/core/hooks/useAds';
import ssrQueryContext from '@orgnc/core/lib/ssrQueryContext';

import globalClasses from '@/components/global.module.scss';
import HashtagLabs from '@/components/HashtagLabs';
import HashtagLabsAd from '@/components/HashtagLabsAd';
import { getSingleShow } from '@/lib/queries/getSingleShow';
import { getTheme } from '@/theme';
import { filterCoreBlocks } from '@/utils/coreBlocks';
import { useTheme } from '@mui/material/styles';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import SEO, { IPageSEOProps } from '@orgnc/core/components/common/SEO';
import { Pagination } from '@orgnc/core/components/molecules';
import PageContent from '@orgnc/core/components/molecules/PageContent';
import {
  FeaturedImage,
  HedCard,
} from '@orgnc/core/components/themes/Elements/components';
import {
  IGetSingleShowQuery,
  IPost,
} from '@orgnc/core/generated/graphql-operations';
import { getOffsetPaginationQuery } from '@orgnc/core/lib/pagination';
import nonNullable from '@orgnc/core/lib/utils/types/nonNullable';
import blocksAdapter from '@orgnc/core/lib/wordpress/api/adapters/blocksAdapter';
import { IBlockFragment } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';
import { IMediaFragment } from '@orgnc/core/lib/wordpress/api/fragments/mediaFragment';
import gqlFetcher from '@orgnc/core/lib/wordpress/api/gqlFetcher';
import prefetchGlobalQueries from '@orgnc/core/lib/wordpress/api/helpers/prefetchGlobalQueries';
import Image from 'next/image';
import React, { useCallback } from 'react';
import { getDomainThemeId } from '../../../../../themes';
import { parseCanonicalDomain } from '../../../../../utils/useCanonicalDomain';

const RECENT_POSTS_SIZE = 12;

export const getServerSideProps: GetServerSideProps = async ({
  params,
  query,
  resolvedUrl,
}) => {
  const { slug } = params!;
  if (!slug) {
    return {
      notFound: true,
    };
  }

  let pageNum = 1;
  if (query.page && typeof query.page === 'string') {
    pageNum = parseInt(query.page, 10);
  }

  const theme = getTheme(getDomainThemeId(parseCanonicalDomain(resolvedUrl)));
  const queryContext = await ssrQueryContext(theme);

  const paginationQuery = getOffsetPaginationQuery(pageNum, RECENT_POSTS_SIZE);

  const { show: showDetails } = await gqlFetcher(
    getSingleShow,
    {
      id: Array.isArray(slug) ? slug[0] : slug,
      episodesSize: 0,
      episodesOffset: 0,
      recentPostsSize: paginationQuery.size,
      recentPostsOffset: paginationQuery.offset,
    },
    'GetSingleShowQuery',
    undefined,
    undefined,
    theme.graphqlClient
  );

  await Promise.all([...Object.values(prefetchGlobalQueries(queryContext))]);

  return {
    props: {
      slug,
      showDetails,
      pageNum,
      dehydratedState: dehydrate(queryContext.queryClient),
    },
  } as const;
};

interface ShowDetailsPageProps {
  slug: string;
  pageNum: number;
  showDetails: Exclude<IGetSingleShowQuery['show'], null>;
}

const listStyle = {
  p: 0,
  '&:not(:last-child)': {
    mr: 2,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const activeBtnStyle = {
  backgroundColor: '#ea252a',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ea252a',
    color: '#ffffff',
  },
};

const inactiveBtnStyle = {
  color: '#202124',
};

const ShowDetailsPage: React.FC<ShowDetailsPageProps> = ({
  slug,
  showDetails,
  pageNum,
}) => {
  const clientTheme = useTheme();
  const { data: adsData } = useAds();
  const recentPosts = (showDetails.recentPosts?.nodes?.filter(nonNullable) ||
    []) as unknown as IPost[];
  const getPageUri = useCallback(
    (page: number | null, params?: Record<string, unknown>) => {
      const newParams = params || {};
      if (page && page > 1) {
        newParams.page = page;
      }
      const queryString = qs.stringify(newParams);
      return `/shows/${slug}/${queryString ? `?${queryString}` : ''}`;
    },
    [slug]
  );
  const totalPages =
    showDetails?.recentPosts?.pageInfo?.offsetPagination?.total || 0;
  const showsPageSEO: IPageSEOProps = {
    title: showDetails.title,
    excerpt: '',
    seo: showDetails.seo,
    date: showDetails.date || '',
    modified: '',
    uri: `/shows/${slug}/`,
    featuredImage: showDetails.featuredImage?.node
      ? {
          node: showDetails.featuredImage.node as unknown as IMediaFragment,
        }
      : null,
    featuredImageUrl: showDetails.featuredImage?.node?.mediaItemUrl || '',
  };
  return (
    <Layout
      ogTemplateInfo={{
        contentType: ContentType.article,
      }}
    >
      <HashtagLabs databaseId={showDetails.databaseId} />
      <SEO page={showsPageSEO} pageNum={pageNum} pageType="Webpage" />
      {adsData?.documentHeadOrganicTagsConfig && (
        <DocumentHeadOrganicTags
          pageId={`shows/${slug}`}
          {...adsData.documentHeadOrganicTagsConfig}
        />
      )}

      <Container maxWidth="lg" sx={{ py: 4 }} component="div">
        <CenterLayout
          maxWidth={`${clientTheme.breakpoints.values.xl}${clientTheme.breakpoints.unit}`}
          sx={{ width: '100%' }}
        >
          <HashtagLabsAd
            unit={'WideBanner_Top'}
            sx={{ mt: { xs: 8, sm: 2 }, height: 250 }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: { xs: 0, md: 4 },
              height: 90,
            }}
          >
            {showDetails.featuredImage && (
              <Image
                className={globalClasses.imgFluid}
                src={showDetails.featuredImage.node?.mediaItemUrl || ''}
                width={80}
                height={80}
                alt={showDetails.title || ''}
              />
            )}
            <Box
              sx={{
                ml: 2,
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #bbbdbf',
                borderBottom: '1px solid #bbbdbf',
                height: '100%',
              }}
            >
              {showDetails.title && (
                <Typography
                  sx={{ flexGrow: 1, typography: { xs: 'h5', md: 'h1' } }}
                  component={'h2'}
                  variant="h1"
                  dangerouslySetInnerHTML={{ __html: showDetails.title }}
                />
              )}

              <MenuList sx={{ display: 'flex', alignItems: 'center' }}>
                <MenuItem sx={listStyle}>
                  <DisablePrefetchLink href={`/shows/${slug}/`} passHref>
                    <Button component="a" href="#" sx={activeBtnStyle}>
                      About
                    </Button>
                  </DisablePrefetchLink>
                </MenuItem>
                {showDetails.episodes?.pageInfo?.offsetPagination?.total ? (
                  <MenuItem sx={listStyle}>
                    <DisablePrefetchLink
                      href={`/shows/${slug}/episodes/`}
                      passHref
                    >
                      <Button
                        component="a"
                        href={`shows/${slug}/episodes`}
                        sx={inactiveBtnStyle}
                      >
                        Episodes
                      </Button>
                    </DisablePrefetchLink>
                  </MenuItem>
                ) : null}
              </MenuList>
            </Box>
          </Box>
          <Box>
            {showDetails.blocks?.length ? (
              <PageContent
                blocks={filterCoreBlocks(
                  showDetails.blocks.map((x) =>
                    blocksAdapter(x as unknown as IBlockFragment)
                  )
                )}
                includeContentBlockStyling
              />
            ) : null}
            {recentPosts.length > 0 ? (
              <>
                <Typography component="h2" sx={{ my: 2 }} variant="h3">
                  Recent Posts
                </Typography>
                <GridLayout>
                  {recentPosts.map((post) => (
                    <StackLayout key={post.databaseId}>
                      {post?.featuredImage?.node?.sourceUrl && (
                        <DisablePrefetchLink href={post.uri || '#'} passHref>
                          <a>
                            <FeaturedImage
                              alt={post?.featuredImage?.node?.altText || ''}
                              ratio="4:3"
                              src={post?.featuredImage?.node?.sourceUrl || ''}
                            />
                          </a>
                        </DisablePrefetchLink>
                      )}
                      <HedCard
                        divider={false}
                        excludeAuthorLink={false}
                        excludeCategoryLink={false}
                        excludePublishDate={false}
                        post={post}
                      />
                    </StackLayout>
                  ))}
                </GridLayout>
                {totalPages > 1 && (
                  <Pagination
                    allowChangeRowPerPage={false}
                    getPageUri={getPageUri}
                    numberOfRows={totalPages}
                    page={pageNum}
                    rowsPerPage={RECENT_POSTS_SIZE}
                    showDividers={false}
                  />
                )}
              </>
            ) : null}
          </Box>
        </CenterLayout>
      </Container>
    </Layout>
  );
};

export default ShowDetailsPage;
